import { MenuItems } from "types/menu";
import admin from "./admin";
import agents from "./agents";
import dashboard from "./dashboard";
import support from "./support";

// ==============================|| MENU ITEMS ||============================== //

const menuItems: MenuItems = {
  items: [dashboard, agents, support, admin],
};

export default menuItems;
