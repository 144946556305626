import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import { Typography } from "@mui/material";

import { MenuItems, NavItemType } from "types/menu";

type Breadcrumb = {
  title: NavItemType["title"];
  url: NavItemType["url"];
};

interface BreadcrumbsProps {
  menuItems: MenuItems;
}

/**
 * Breadcrumbs Component
 * ---------------------
 * This component is a simple implementation of breadcrumbs designed to handle
 * the current application's breadrumbs needs. It dynamically resolves breadcrumbs
 * based on the `menuItems` provided, including support for static titles,
 * clickable links, and skipping dynamic segments (e.g., IDs).
 *
 * Note:
 * If more complex URL structures need to be supported in the future
 * (e.g., hierarchical paths like "/agents/policies/add"), the `breadcrumbsMap`
 * can be enhanced to include nested `children` for each segment.
 * This would allow recursive resolution of breadcrumbs, making the component
 * more flexible and capable of handling deeper URL hierarchies dynamically.
 *
 * Example:
 * breadcrumbsMap: {
 *   add: { title: "Add Agent" },
 *   policy: {
 *     title: "Policies",
 *     children: {
 *       add: { title: "Add Policy" }
 *     }
 *   }
 * }
 *
 * This recursive enhancement would require the breadcrumb resolution logic
 * to traverse the `breadcrumbsMap` hierarchy instead of flat lookup.
 */

const Breadcrumbs = ({ menuItems }: BreadcrumbsProps) => {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  useEffect(() => {
    const getFallbackTitle = (segment: string) => {
      if (!segment) return "";
      return segment.charAt(0).toUpperCase() + segment.slice(1);
    };

    const processChildBreadcrumbs = (child: NavItemType, pathnames: string[]): Breadcrumb[] => {
      const breadcrumbsList: Breadcrumb[] = [];
      if (child.type === "item" && child.breadcrumbs && pathnames[0] === child.url?.split("/")[1]) {
        // Add the first level
        breadcrumbsList.push({ title: child.title, url: child.url });

        let currentPath = child.url;
        let skipNextSegment = false;

        // Process remaining segments
        pathnames.slice(1).forEach((segment, index) => {
          // Skip if this segment should be skipped
          if (skipNextSegment) {
            skipNextSegment = false;
            return;
          }

          const breadcrumbInfo = child.breadcrumbsMap?.[segment];
          currentPath = `${currentPath}/${segment}`;

          if (breadcrumbInfo?.hasId) {
            skipNextSegment = true;
          }

          const remainingVisibleSegments = pathnames.slice(1).filter((_, i) => {
            return !skipNextSegment || i !== index + 1;
          });
          const isLastVisibleSegment =
            segment === remainingVisibleSegments[remainingVisibleSegments.length - 1];

          breadcrumbsList.push({
            title: breadcrumbInfo?.title || getFallbackTitle(segment),
            url: isLastVisibleSegment ? undefined : currentPath,
          });
        });
      }
      return breadcrumbsList;
    };

    const processMenuItems = (pathnames: string[]): Breadcrumb[] => {
      const breadcrumbsList: Breadcrumb[] = [];
      menuItems.items.forEach((menu) => {
        if (menu.type === "group" && menu.children) {
          menu.children.forEach((child) => {
            breadcrumbsList.push(...processChildBreadcrumbs(child, pathnames));
          });
        }
      });
      return breadcrumbsList;
    };

    const pathnames = location.pathname.split("/").filter((x) => x);
    const breadcrumbsList = processMenuItems(pathnames);
    setBreadcrumbs(breadcrumbsList);
  }, [location.pathname, menuItems]);

  return (
    breadcrumbs.length > 1 && (
      <MuiBreadcrumbs aria-label="breadcrumbs" sx={{ mb: "15px", mt: { xs: 0, lg: "15px" } }}>
        {breadcrumbs.map((breadcrumb, index) =>
          breadcrumb.url ? (
            <Typography
              key={index}
              component={Link}
              to={breadcrumb.url}
              color="textSecondary"
              variant="body1"
              sx={{ textDecoration: "none" }}
            >
              {breadcrumb.title}
            </Typography>
          ) : (
            <Typography key={index} color="textPrimary" variant="body1">
              {breadcrumb.title}
            </Typography>
          )
        )}
      </MuiBreadcrumbs>
    )
  );
};

export default Breadcrumbs;
