// material-ui
import { TypographyVariantsOptions } from "@mui/material/styles";

// types
import { FontFamily } from "types/config";

// ==============================|| DEFAULT THEME - TYPOGRAPHY ||============================== //

export default function Typography(fontFamily: FontFamily): TypographyVariantsOptions {
  return {
    htmlFontSize: 16,
    fontFamily,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontWeight: 600,
      fontSize: "38px",
      lineHeight: "46px",
    },
    h2: {
      fontWeight: 600,
      fontSize: "30px",
      lineHeight: "38px",
    },
    h3: {
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "32px",
    },
    h4: {
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "28px",
    },
    h5: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
    },
    h6: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "22px",
    },
    caption: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "20px",
    },
    body1: {
      fontSize: "14px",
      lineHeight: "22px",
    },
    body2: {
      fontSize: "12px",
      lineHeight: "20px",
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
    },
    overline: {
      fontSize: "12px",
      lineHeight: "20px",
    },
    button: {
      textTransform: "none",
    },
  };
}
